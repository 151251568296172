/* src/Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
    text-align: center;
    padding: 20px;
  }
  
  .login-logo {
    width: 150px;  /* Adjust this value based on your logo size */
    height: auto;
    margin-bottom: 20px;
  }
  
  .login-header {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-button {
    background-color: #4285f4; /* Google Blue */
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    width: 220px;
    transition: background-color 0.3s ease;
  }
  
  .login-button.apple {
    background-color: #000000; /* Apple Black */
  }
  
  .login-button:hover {
    opacity: 0.8;
  }
  
  .login-button:focus {
    outline: none;
  }  
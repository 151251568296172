/* src/DeleteAccount.css */

.delete-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
    text-align: center;
    padding: 20px;
  }
  
  .delete-account-logo {
    width: 150px;  /* Adjust this value based on your logo size */
    height: auto;
    margin-bottom: 20px;
  }
  
  .delete-account-header {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .delete-account-text {
    margin-bottom: 20px;
    color: #555;
    font-size: 1.1rem;
  }
  
  .delete-account-button {
    background-color: #d9534f; /* Red color for delete button */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    width: 220px;
    transition: background-color 0.3s ease;
  }
  
  .delete-account-button:hover {
    background-color: #c9302c;
  }
  
  .delete-account-button:focus {
    outline: none;
  }
  